:root {
    /*
    Font Format as declared in documentation
    font: font-style font-variant font-weight font-size/line-height font-family ;
    Bold=700, Semibold/Demibold=600, normal=regular
    */
    --font-h1: normal normal bold 104px/124px Noir No1, sans-serif;
    --font-h2: normal normal bold 64px/76px Noir No1, sans-serif;
    --font-h3: normal normal bold 48px/56px Noir No1, sans-serif;
    --font-h4: normal normal 600 32px/38px Noir No1, sans-serif;
    --font-h5: normal normal bold 24px/28px Noir No1, sans-serif;
    --font-h6: normal normal normal 24px/28px Noir No1, sans-serif;
    --font-subtitle-1: normal normal 600 20px/24px Noir No1, sans-serif;
    --font-subtitle-2: normal normal 600 20px/20px Noir No1, sans-serif;
    --font-body-1: normal normal normal 16px/24px Noir No1, sans-serif;
    --font-body-2: normal normal bold 16px/24px Noir No1, sans-serif;
    --font-body-3: normal normal normal 14px/18px Noir No1, sans-serif;
    --font-overline: normal normal normal 14px/14px Noir No1, sans-serif;
    --font-button: normal normal bold 14px/14px Noir No1, sans-serif;
    --font-caption: normal normal normal 12px/14px Noir No1, sans-serif;

    /*Colors for theme*/
    --primary-bg-color: 255, 255, 255, 1;/*#FFFFFF */
    --secondary-bg-color: 225, 237, 204, 1;/*#E1EDCC*/
    --tertiary-bg-color: 241, 243, 245, 1;/*#F1F3F5*/
    --dark-bg-color: 0, 0, 0, 1;/*#000000*/
    --accesories-bg-color: 213, 237, 146, 1;/*#D4ED92*/
    --highlight-bg-color: 241, 243, 245, 1;/*#F1F3F5*/
    --form-bg-color: 225, 237, 204, 1;/*#E1EDCC*/
    --primary-cta-color: 133, 173, 30, 1;/*#85AD1E*/
    --primary-cta-dark-color: 133, 173, 30, 1;/*#85AD1E*/
    --loader-color: 133, 173, 30, 1;/*#85AD1E*/
    --loader-dark-color: 255, 255, 255, 1;/*#FFFFFF */
    --active-color: 0, 0, 0, 1;/*#000000*/
    --active-dark-color: 133, 173, 30, 1;/*#85AD1E*/
    --primary-error-color: 255, 0, 0, 1;/*#FF0000*/
    --secondary-error-color: 255, 231, 231, 1;/*#FFE7E7*/
    --success-color: 133, 173, 30, 1;/*#85AD1E*/
    --primary-text-color: 0, 0, 0, 1;/*#000000*/
    --primary-text-dark-color: 255, 255, 255, 1;/*#FFFFFF */
    --secondary-text-color: 104, 98, 98, 1;/*#686262*/
    --countdown-text-color: 255, 255, 255, 1;/*#FFFFFF */
    --overlay-img-1-color: 133, 173, 30, 1;/*#85AD1E*/
    --overlay-img-2-color: 133, 173, 30, 1;/*#85AD1E*/
    --overlay-img-3-color: 133, 173, 30, 1;/*#85AD1E*/
    --overlay-img-4-color: 133, 173, 30;/*#85AD1E. Only one not RGBA*/
    --overlay-bg-1-color: 241, 243, 245, 1;/*#F1F3F5*/
    --overlay-bg-2-color: 0, 0, 0, 1;/*#000000*/
    --overlay-bg-3-color: 104, 98, 98, 1;/*#686262*/
}