.header {
    --logo-height: 74px;
    --org-logo-height: 50px;
}

.header {
    width: max-content;
    display: grid;
    align-items: center;
    justify-items: center;
    grid-auto-flow: column;
    grid-column-gap: 20px;
    column-gap: 20px;
    grid-template-columns: max-content max-content max-content;
    position: absolute;
    top: 48px;
    left: 56px;
    z-index: 3;
}

.separator {
    width: 1px;
    height: 100%;
    margin-left: 10px;
    background: rgb(var(--primary-text-dark-color));
}

.logo {
    height: var(--logo-height);
    -webkit-filter: invert(100%);
    filter: invert(100%);
}

.org_logo {
    height: var(--org-logo-height);
}

@media(max-width: 1440px) {
    .header {
        --logo-height: calc(48px + (74 - 48) * ((100vw - 769px) / (1440 - 769)));
        --org-logo-height: calc(48px + (50 - 48) * ((100vw - 769px) / (1440 - 769)));
    }
}

@media(max-width: 1150px) {
    .header {
        top: 16px;
    }
}

@media(max-width: 768px) {
    .header {
        --logo-height: calc(40px + (48 - 40) * ((100vw - 375px) / (768 - 375)));
        --org-logo-height: calc(40px + (48 - 40) * ((100vw - 375px) / (768 - 375)));
    }
    .header {
        left: 7.8%;
    }
}