.grid_view {
    grid-row: 3;
    grid-column: 2;
    width: 100%;
    height: 100%;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    overflow: hidden;
    position: relative;
}

.scroll {
    position: absolute;
    width: 60px;
    height: 60px;
    border: none;
    border-radius: 50%;
    padding: 10px;
    background: #ffffff7e;
    display: grid;
    justify-items: center;
    align-items: center;
}

.scroll:disabled {
    display: none;
}

.left {
    left: 0px;
    z-index: 1;
    bottom: calc(50% - 60px / 2);
    transform: rotate(270deg);
}

.right {
    right: 0px;
    z-index: 1;
    bottom: calc(50% - 60px / 2);
    transform: rotate(90deg);
}