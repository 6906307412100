.messages {
    height: 100%;
    padding-right: 13px;
    display: flex;
    flex-direction: column-reverse;
    overflow-y: auto;
}

.no_messages {
    display: grid;
    justify-content: center;
    align-content: center;
    justify-items: center;
}

.wave_hand {
    padding: 30px;
    width: 104px;
    height: 104px;
    border-radius: 100px;
    background-color: rgba(var(--accesories-bg-color));
}

.title {
    font: var(--font-body-2);
    font-size: 16px;
    line-height: 24px;
    color: rgba(var(--primary-text-color));
}

.text {
    font: var(--font-body-1);
    font-size: 16px;
    line-height: 24px;
    color: rgba(var(--primary-text-color));
}