.spinner {
    width: 70px;
    text-align: center;
}

.spinner>div {
    width: 15px;
    height: 15px;
    margin-left: 5px;
    background-color: rgba(var(--loader-dark-color));
    border-radius: 100%;
    display: inline-block;
    animation: bounceDelay 1.4s infinite ease-in-out both;
}

.spinner .dot1 {
    animation-delay: -0.32s;
}

.spinner .dot2 {
    animation-delay: -0.16s;
}

@keyframes bounceDelay {
    0%, 80%, 100% {
        transform: scale(0);
    }
    40% {
        transform: scale(1.0);
    }
}