.alt_hero {
    --height: 681px;
    --details-padding-L: 140px;
    --title-font-size: 48px;
    --body-font-size: 16px;
    --body-margin-T: 15px;
}

.alt_hero {
    width: 100%;
    max-width: 2100px;
    height: var(--height);
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.thumbnail {
    width: 100%;
    height: var(--height);
    object-position: center top;
    object-fit: cover;
}

.details {
    width: 100%;
    height: 100%;
    padding-left: var(--details-padding-L);
    padding-right: var(--discovery-page-padding);
    background-color: rgba(var(--dark-bg-color));
    display: grid;
    grid-template-rows: 1fr max-content max-content 1fr;
}

.title {
    grid-row: 2;
    font: var(--font-h3);
    font-size: var(--title-font-size);
    line-height: calc(var(--title-font-size) + 10px);
    text-transform: uppercase;
    color: #E3DED8;
}

.body {
    grid-row: 3;
    margin-top: var(--body-margin-T);
    font: var(--font-body-1);
    font-size: var(--body-font-size);
    line-height: calc(var(--body-font-size) + 6px);
    color: #E3DED8;
}

@media(max-width: 1680px) {
    .alt_hero {
        --height: calc(415px + (681 - 415) * ((100vw - 1024px) / (1680 - 1024)));
        --title-font-size: calc(35px + (48 - 35) * ((100vw - 1024px) / (1680 - 1024)));
        --body-margin-T: calc(10px + (15 - 10) * ((100vw - 1024px) / (1680 - 1024)));
        --details-padding-L: calc(50px + (140 - 50) * ((100vw - 1024px) / (1680 - 1024)));
    }
}

@media(max-width: 1023px) {
    .alt_hero {
        --deck-font-size: calc(17px + (30 - 17) * ((100vw - 320px) / (1023 - 320)));
        --title-font-size: calc(25px + (48 - 25) * ((100vw - 320px) / (1023 - 320)));
        --body-font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1023 - 320)));
        --image-width: calc(320px + (1023 - 320) * ((100vw - 320px) / (1023 - 320)));
        --image-height: calc((450 / 768) * var(--image-width));
    }
    .alt_hero {
        width: 100%;
        height: max-content;
        grid-template-columns: 1fr;
        grid-template-rows:1fr max-content;
    }
    .thumbnail {
        grid-row: 1;
        height: var(--image-height);
    }
    .details {
        grid-row: 2;
        padding: 30px var(--discovery-page-padding);
        grid-template-rows: repeat(2, max-content);
    }
    .title {
        grid-row: 1;
        grid-column: 1;
    }
    .body {
        grid-row: 2;
        grid-column: 1;
    }
}