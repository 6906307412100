.footer {
    --logo-height: 67px;
    --footer-padding-TB: 30px;
}

.footer {
    grid-row: 3;
    width: 100vw;
    background-color:rgba(var(--tertiary-bg-color));
    padding: var(--footer-padding-TB) 20px;
    display: grid;
    align-items: center;
    justify-items: flex-start;
    grid-auto-flow: column;
    column-gap: 20px;
    row-gap: 20px;
    grid-template-columns: max-content 1fr;
    grid-template-rows: max-content max-content;
}

.logos {
    grid-row: 1;
    grid-column: 1;
    display: grid;
    align-items: center;
    justify-items: center;
    grid-auto-flow: column;
    column-gap: 70px;
}

.logo {
    height: var(--logo-height);
}

.privacy_policy {
    grid-row: 2;
    grid-column: 1;
    display: grid;
    align-items: center;
    justify-items: center;
    grid-auto-flow: column;
    gap: 10px;
    font-weight: 400;
    font-size: 12px;
}
.privacy_policy p {
    font: var(--font-body-1);
    font-size: 12px;
    color: rgba(var(--secondary-text-color));
}

.privacy_policy a {
    font: var(--font-body-2);
    font-size: 12px;
    color: rgba(var(--secondary-text-color));
    text-decoration: none;
}
.privacy_policy a:hover { color: rgba(var(--primary-text-color))}

.social_media {
    grid-row: 2;
    grid-column: 2;
    justify-self: flex-end;
    display: grid;
    align-items: center;
    justify-items: center;
    grid-auto-flow: column;
    column-gap: 10px;
}

.social_media a {
    font: var(--font-body-2);
    font-size: 12px;
    color: rgba(var(--secondary-text-color));
    text-decoration: none;
}
.social_media a:hover { color: rgba(var(--primary-text-color))}


@media(max-width: 700px) {
    .footer {
        justify-items: center;
        grid-template-columns: 1fr;
        grid-template-rows: max-content max-content max-content;
    }
    .social_media {
        justify-self: center;
        grid-row: 2;
        grid-column: 1;
    }
    .privacy_policy {
        grid-row: 3;
    }
}

@media(max-width: 425px) {
    .footer {
        --logo-height: calc(43px + (67 - 43) * ((100vw - 320px) / (425 - 320)));
        --footer-padding-TB: calc(20px + (30 - 20) * ((100vw - 320px) / (425 - 320)));
    }
}

@media(max-width: 375px) {
    .privacy_policy {grid-auto-flow: row;}
    .privacy_policy p {grid-row: 3;}
}