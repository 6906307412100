.video_highlight {
    --video-height: calc((9 / 16) * (100vw / 2));
    --deck-font-size: 20px;
    --title-font-size: 59px;
    --body-font-size: 16px;
    --details-padding-L: 140px;
}

.video_highlight {
    width: 100%;
    max-width: 2100px;
    height: max-content;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.description {
    background-color: rgba(var(--highlight-bg-color));
    padding-right: var(--discovery-page-padding);
    padding-left: var(--details-padding-L);
    display: grid;
    align-items: center;
    align-content: center;
    grid-template-rows: max-content max-content max-content;
}

.deck {
    font: var(--font-overline);
    font-size: var(--deck-font-size);
    line-height: 29px;
    text-transform: capitalize;
    color: rgba(var(--secondary-text-color));
}

.title {
    font: var(--font-h3);
    font-size: var(--title-font-size);
    line-height: calc(var(--title-font-size) + 10px);
    color: rgba(var(--primary-text-color));
    padding-bottom: 20px;
}

.body {
    font: var(--font-body-1);
    font-size: var(--body-font-size);
    line-height: calc(var(--body-font-size) + 6px);
    color: rgba(var(--primary-text-color));
}

.video {
    height: var(--video-height);
}

@media(min-width:2101px) {
    .video_highlight {
        --video-height: 590.625px;
    }
}

@media(max-width: 1680px) {
    .video_highlight {
        --deck-font-size: calc(12px + (20 - 12) * ((100vw - 1024px) / (1680 - 1024)));
        --title-font-size: calc(36px + (59 - 36) * ((100vw - 1024px) / (1680 - 1024)));
        --details-padding-L: calc(50px + (140 - 50) * ((100vw - 1024px) / (1680 - 1024)));
    }
}

@media(max-width: 1023px) {
    .video_highlight {
        --video-height: calc((9 / 16) * 100vw);
        --deck-font-size: calc(17px + (20 - 17) * ((100vw - 320px) / (1023 - 320)));
        --title-font-size: calc(25px + (36 - 25) * ((100vw - 320px) / (1023 - 320)));
    }
    .video_highlight {
        grid-template-columns: 1fr;
        grid-template-rows: max-content max-content;
    }
    .video {
        grid-row: 1;
        grid-column: 1;
    }
    .description {
        padding: 30px var(--discovery-page-padding);
    }
    .deck {
        grid-column: 1;
        padding-bottom: 10px;
    }
    .title {
        grid-column: 1;
    }
    .body {
        grid-row: 4;
        grid-column: 1;
    }
}

@media(max-width: 768px) {
    .body {
        padding-right: 0px;
    }
}