.input {
    height: max-content;
    padding-right: 13px;
    outline: none;
    display: grid;
    align-items: flex-end;
    justify-items: flex-end;
    grid-template-columns: 1fr max-content;
    column-gap: 1rem;
}

.wrapper {
    grid-column: 1;
    display: grid;
    justify-items: center;
    align-items: center;
    width: 100%;
    height: max-content;
    padding: 8px;
    background: rgba(var(--primary-text-dark-color));
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
}

.wrapper>span {
    grid-row: 1;
    grid-column: 1;
    width: 100%;
    max-height: 95px;
    pointer-events: none;
    word-break: break-word;
    overflow: hidden;
    opacity: 0;
}

.text_input {
    grid-row: 1;
    grid-column: 1;
    width: 100%;
    height: 100%;
    font: var(--font-body-1);
    font-size: 16px;
    line-height: 24px;
    color: rgba(var(--secondary-text-color));
    border: none;
    outline: none;
    resize: none;
    background: transparent;
    overflow-y: auto;
}

.text_input::placeholder {
    font-style: italic;
    color: rgba(var(--secondary-text-color));
    opacity: 0.7;
}

.button {
    grid-column: 2;
    margin-bottom: 15px;
    border: none;
    background: transparent;
}

.icon path {fill: rgba(var(--active-color));}