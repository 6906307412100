.event_info_page {
    --body-padding-RL: 56px;
}

.event_info_page {
    width: 100vw;
    height: 100%;
    display: grid;
    justify-items: center;
    align-items: flex-start;
    grid-template-columns: 1fr;
    grid-template-rows: max-content 1fr;
}

.body {
    width: 100vw;
    max-width: 2100px;
    height: 100%;
    padding: 40px var(--body-padding-RL);
    padding-bottom: 120px;
    display: grid;
    justify-items: center;
    align-items: flex-start;
    grid-template-columns: 1fr;
    gap: 16px;
}

.loading {
    grid-row: 2;
    width: 100%;
    height: 100%;
}

@media(max-width: 1680px) {
    .event_info_page {
        --body-padding-RL: calc(28px + (56 - 28) * ((100vw - 769px) / (1680 - 769)));
    }
}

@media(max-width: 768px) {
    .event_info_page {
        --body-padding-RL: calc(20px + (40 - 20) * ((100vw - 376px) / (768 - 376)));
    }

    .body {
        grid-template-columns: 1fr;
        grid-auto-rows: max-content;
    }
}