.menu_settings {
    width: max-content;
    height: max-content;
}

.title {
    font: var(--font-body-2);
    font-size: 16px;
    line-height: 24px;
}

.item {
    display: grid;
    gap: 5px;
    align-items: center;
    justify-items: flex-start;
    grid-template-columns: max-content 1fr;
    cursor: pointer;
}

.item input {
    width: 16px;
    height: 16px;
}

.item label {
    font: var(--font-body-1);
    font-size: 16px;
    line-height: 24px;
}