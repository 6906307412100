.member_card {
    display: grid;
    grid-auto-rows: max-content;
    /* grid-template-rows: max-content 84px repeat(1, max-content) 288px max-content; */
    gap: 16px;
    justify-items: flex-start;
}

.profile_image {
    width: 64px;
    height: 64px;
    filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.05));
    border-radius: 100px;
    object-fit: cover;
}

.name {
    font: var(--font-h6);
    font-size: 24px;
    line-height: 28px;
    font-weight: 700;
    color: rgba(var(--primary-text-color));
    text-transform: capitalize;
}

.info {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: max-content 1fr;
    gap: 4px;
    align-items: center;
    font: var(--font-body-1);
    font-size: 16px;
    line-height: 24px;
    color: rgba(var(--primary-text-color));
}

.icon {
    width: 16px;
    height: 16px;
}

.icon path {
    fill: rgba(var(--primary-text-color));
}

.statement {
    font: var(--font-body-2);
    font-size: 16px;
    line-height: 24px;
    color: rgba(var(--primary-text-color));
}

.bio {
    font: var(--font-body-1);
    font-size: 16px;
    line-height: 24px;
    color: rgba(var(--primary-text-color));
}

.button {
    font: var(--font-button);
    color: rgba(var(--primary-text-color));
    background: transparent;
    border: none;
    text-decoration: none;
    text-transform: uppercase;
}