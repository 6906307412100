.account_page {
    --account-page-padding: 56px;
}

.account_page {
    width: 100%;
    height: 100%;
    padding: var(--account-page-padding);
    display: grid;
    grid-template-columns: minmax(max-content, 425px);
    justify-content: center;
    align-content: flex-start;
    gap: 30px;
}

.title {
    font: var(--font-h4);
    font-size: 32px;
    line-height: 38px;
    color: rgba(var(--primary-text-color));
}

.error_message {
    width: 100%;
    height: max-content;
    font: var(--font-body-1);
    font-size: 18px;
    background: rgba(var(--primary-error-color));
    color: rgba(var(--primary-text-dark-color));
    border-radius: 2px;
    padding: 10px 10px;
}

@media(max-width: 1023px) {
    .account_page {
        --account-page-padding: calc(10px + (32 - 10) * ((100vw - 320px) / (1023 - 320)));
    }
}

@media(max-width: 600px) {
    .account_page {
        grid-auto-flow: row;
        grid-template-columns: 1fr;
        grid-auto-rows: max-content;
    }
}