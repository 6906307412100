.invited_guests {
    background: transparent;
}

.invited_guests>div {
    width: 100vw;
    max-width: 100vw;
    height: 100vh;
    border-radius: 0;
    background: rgba(var(--secondary-bg-color));
    opacity: 0.95;
    display: grid;
    grid-template-rows: max-content 1fr;
    row-gap: 12px;
    overflow: hidden;
    z-index: 1;
    text-align: left;
}

.guests {
    width: 100%;
    height: 100%;
    padding-right: 15px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: max-content;
    column-gap: 16px;
    row-gap: 48px;
    align-content: flex-start;
    overflow-y: auto;
}

.close_button {
    grid-row: 1;
    justify-self: flex-end;
    border: none;
    background: transparent;
    padding-bottom: 38px;
}

.close_icon path {
    stroke: rgba(var(--primary-text-color));
}

@media(max-width: 1023px) {
    .guests {grid-template-columns: 1fr 1fr;}
}

@media(max-width: 768px) {
    .guests {grid-template-columns: 1fr}
}