.header {
    --header-height: 80px;
    --logo-height: 48px;
}

.header {
    grid-row: 1;
    width: 100vw;
    height: var(--header-height);
    background-color:rgba(var(--tertiary-bg-color)); 
    backdrop-filter: saturate(180%) blur(5px);
    padding: 0px 20px;
    display: grid;
    align-items: center;
    justify-items: center;
    grid-auto-flow: column;
    grid-column-gap: 20px;
    column-gap: 20px;
    grid-template-columns: max-content max-content max-content 1fr;
    top: 0;
    position: sticky;
    z-index: 2;
}

.separator {
    width: 1px;
    height: var(--logo-height);
    margin-left: 10px;
    background: rgb(var(--primary-text-color));
}

.logo {
    height: var(--logo-height);
}

.header_button {justify-self: flex-end;}

@media(max-width: 1023px) {
    .header {
        --logo-height: calc(34px + (48 - 34) * ((100vw - 375px) / (1023 - 375)));
    }
}

@media(max-width: 600px) {
    .header {
        --header-height: calc(74px + (80 - 74) * ((100vw - 375px) / (600 - 375)));
    }
}