.raise_hand_notification {
    position: absolute;
    width: 100%;
    height: 90%;
    pointer-events: none;
    display: grid;
    align-content: flex-end;
    grid-template-columns: 1fr;
    grid-template-rows: max-content;
}

.raised_hand {
    width: max-content;
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-template-rows: 1fr;
    align-items: center;
    column-gap: 5px;
    background: rgba(var(--dark-bg-color));
    border-radius: 100px;
    padding: 5px 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    animation: fadeOut 300ms;
    animation-delay: 10000ms;
    animation-fill-mode: forwards;
}

.raise_hand_icon {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: rgba(var(--active-color));
}

.icon {
    grid-row: 1;
    grid-column: 1;
    justify-self: center;
    width: 20px;
    height: 20px;
}

.name {
    justify-self: flex-start;
    grid-row: 1;
    grid-column: 2;
    font: var(--font-body-2);
    font-size: 16px;
    line-height: 24px;
    color: rgba(var(--primary-text-dark-color));
    letter-spacing: 0.04em;
}

@keyframes fadeOut {
    from {
        opacity: 1;
        transform: translateY(0%)
    }

    to {
        opacity: 0;
        transform: translateY(-100%)
    }
}