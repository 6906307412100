.countdown {
    --padding-RL: 56px;
    --title-font-size: 35px;
    --subtitle-font-size: 20px;
}

.countdown {
    grid-row: 1;
    grid-column: 2;
    justify-self: flex-start;
    display: grid;
    row-gap: 16px;
    padding: 0 var(--padding-RL);
}

.date {
    font: var(--font-subtitle-2);
    font-size: var(--subtitle-font-size);
    color: rgba(var(--countdown-text-color));
}

.event_title {
    width: 100%;
    font: var(--font-h2);
    font-size: var(--title-font-size);
    line-height: calc(var(--title-font-size) + 10px);
    color: rgba(var(--countdown-text-color));
    padding-bottom: 24px;
}

@media(max-width: 1000px) {
    .countdown {
        --padding-RL: calc(34px + (56 - 34) * ((100vw - 769px) / (1000 - 769)));
    }
}

@media(max-width: 768px) {
    .countdown {
        --padding-RL: calc(15px + (34 - 15) * ((100vw - 375px) / (768 - 375)));
        --title-font-size: calc(26px + (35 - 26) * ((100vw - 375px) / (768 - 375)));
        --subtitle-font-size: calc(16px + (20 - 16) * ((100vw - 375px) / (768 - 375)));
    }
    .countdown {
        padding: 15px var(--padding-RL);
    }
}