.menu_button {
    justify-self: flex-end;
    display: grid;
    column-gap: 4px;
    align-items: center;
    justify-items: center;
    grid-auto-flow: column;
}

.menu {
    grid-row: 1;
    grid-column: 1;
    position: relative;
    width: 17px;
    height: 24px;
}

.container {
    position: absolute;
    bottom: 50px;
    left: 0px;
    width: max-content;
    height: max-content;
    padding: 16px;
    border-radius: 8px;
    background-color: rgba(var(--tertiary-bg-color));
    display: grid;
    gap: 10px;
    box-shadow: 0px 4px 4px #0000001a;
}

.arrow {
    grid-row: 1;
    grid-column: 2;
    width: 17px;
    height: 24px;
    background: transparent;
    display: grid;
    align-items: center;
    justify-items: center;
    outline: none;
    border: 1px solid transparent;
}

.arrow:active {
    border-radius: 5px;
    border: 1px solid rgba(var(--active-color));
}

.icon {
    width: 9px;
}

.icon path {
    stroke: rgba(var(--overlay-bg-1-color));
}

.arrow:active .icon path {
    stroke: rgba(var(--active-color));
}

.active {
    border-radius: 5px;
    border: 1px solid rgba(var(--active-color));
}

.active .icon path {
    stroke: rgba(var(--active-color));
}

.menu_active {
    grid-row: 1;
    grid-column: 1;
    background: rgba(var(--active-color));
}