* {
  padding: 0;
  margin: 0;
  font-family: lato, sans-serif;
  box-sizing: border-box;
}

p {
  white-space: pre-line;
}

html,
body,
#root {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

#root {
  overflow-y: auto;
  display: grid;
  justify-items: center;
  grid-template-rows: max-content 1fr max-content;
}

button {
  cursor: pointer;
  user-select: none;
}

button:enabled:active {
  opacity: 0.7;
}

button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

img,
svg {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

@font-face {
  font-family: 'Noir No1';
  src: url('fonts/NoirNo1.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Noir No1';
  src: url('fonts/NoirNo1-Demibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Noir No1';
  src: url('fonts/NoirNo1-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Fira Sans Condensed';
  src: url('fonts/FiraSansCondensed-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}