.end_call {
    justify-self: center !important;
}

.button {
    width: max-content;
    min-width: 100%;
    height: max-content;
    font-size: 14px;
    padding: 7px 10px;
    text-transform: none;
    background: rgba(var(--primary-error-color));
}

.button:active {
    background: rgba(var(--primary-error-color));
}

.green_button {
    background: rgba(76, 175, 80, 1);
}

.green_button:active {
    background: rgba(76, 175, 80, 0.9);
}

.end_button {
    background: rgba(var(--primary-error-color))
}

.end_button svg path {
    fill: rgba(var(--overlay-bg-1-color));
}

.end_button:active {
    background: rgba(var(--primary-error-color))
}

.end_button:active svg path {
    fill: inherit
}

.modal_buttons {
    width: 100%;
    margin-top: 20px;
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
}
.modal_button_user_score{
    grid-template-columns: 1fr !important;
    width: 50% !important;
    margin: 20px auto 0 auto !important;
}

.modal_buttons>button {
    width: 100%;
    text-transform: none;
}

.end {
    background: rgba(var(--primary-error-color));
}

.title {
    font: var(--font-h6);
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: rgba(var(--primary-text-color));
}

.text {
    font: var(--font-h6);
    font-size: 24px;
    line-height: 28px;
    color: rgba(var(--primary-text-color));
}

.rating_container {
    margin-top: 20px;
}