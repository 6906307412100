.share {
    width: max-content;
    position: relative;
    display: grid;
    row-gap: 10px;
    grid-auto-flow: row;
    justify-items: center;
    align-items: center;
}

.social_medias {
    width: max-content;
    height: max-content;
    position: absolute;
    left: 45px;
    display: grid;
    column-gap: 5px;
    grid-auto-flow: column;
    justify-items: center;
    align-items: center;
    z-index: 1;
}

.social_button {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: none;
    display: grid;
    align-items: center;
    justify-items: center;
}

.social_icon {
    width: 100%;
}

.social_icon path {
    fill: rgba(var(--primary-cta-color));
}

.button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: none;
    background-color: transparent;
    display: grid;
    align-items: center;
    justify-items: center;
}

.share_icon {
    width: 21.33px;
    height: 21.33px;
}