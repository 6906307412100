.participant {
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.5);
    filter: drop-shadow(1px 1px 6px rgba(0, 0, 0, 0.15));
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.speaker {
    box-shadow: 0 0 0 4px rgba(var(--active-color));
}

.name {
    position: absolute;
    right: 0;
    bottom: 0;
    padding-right: 10px;
    font: var(--font-body-1);
    font-size: 14px;
    line-height: 18px;
    color: rgba(var(--primary-text-dark-color));
    background-color: rgba(var(--overlay-bg-2-color));
    display: grid;
    justify-items: center;
    align-items: center;
    grid-template-columns: max-content 1fr;
    z-index: 1;
}

.speaker .name {
    color: rgba(var(--primary-text-color));
    background-color: rgba(var(--active-color));
    margin-bottom: -3.5px;
}

.mic {
    width: 30px;
}

.speaker .mic path {
    fill: rgba(var(--primary-text-color));
}

.raised_hand {
    width: 25px;
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 10px;
    margin-top: 10px;
    z-index: 2;
}

.video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.avatar {
    position: absolute;
    width: 30%;
    border-radius: 50%;
    object-fit: cover;
}