:root {
    --tawk-to-button-size: 3.75rem;
}

.tawk_to * {
    border: none
}

.tawk_to {
    width: 145px;
    height: max-content;
    position: fixed;
    bottom: 0px;
    right: 0px;
    z-index: 100;
}

.open {
    width: 280px;
}

.loading {
    width: 280px;
    height: 450px;
}

.iframe_wrapper {
    width: 100%;
    height: 450px;
    background-color: #fff;
    display: none;
}

.open .iframe_wrapper {
    display: block;
    position: relative;
    top: -1px;
    left: 1px;
    z-index: -1;
}

.iframe {
    width: 100%;
    height: 100%;
}

.button {
    width: 100%;
    height: 60px;
    padding: 0px 12px;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: max-content 1fr max-content;
    justify-items: center;
    align-items: center;
}

.open_button {
    position: relative;
    left: 2px;
    height: 40px;
    padding: 0px 12px;
    border-top-left-radius: 10px;
    background-color: rgba(var(--dark-bg-color));
}

.button:active {
    background-color: rgba(var(--dark-bg-color)) !important;
    opacity: 0.9;
}

.button_text {
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(var(--overlay-bg-1-color));
}

.message_icon {
    width: 24px;
}

.arrow_icon {
    width: 20px;
    transform: rotate(180deg);
}

.arrow_icon path {
    stroke: rgba(var(--overlay-bg-1-color));
}