.modal {
    background: rgba(var(--secondary-bg-color));
    opacity: 0.95;
    overflow: hidden;
    overflow-y: auto;
}

.modal>div {
    background: transparent;
}

.form {
    width: 100%;
    max-width: 425px;
    height: 100%;
    display: grid;
    grid-auto-rows: max-content;
    row-gap: 24px;
    justify-items: flex-start;
    text-align: left;
}

.title {
    font: var(--font-h4);
    font-size: 32px;
    line-height: 38px;
    color: rgba(var(--primary-text-color));
}

.close_button {
    justify-self: flex-end;
    border: none;
    background: transparent;
}

.close_icon path {
    stroke: rgba(var(--primary-text-color));
}

.error_message {
    width: 100%;
    height: max-content;
    font: var(--font-body-1);
    font-size: 18px;
    background: rgba(var(--primary-error-color));
    color: rgba(var(--primary-text-dark-color));
    border-radius: 2px;
    padding: 10px 10px;
}