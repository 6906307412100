.authentication {
    position: relative;
    grid-row: 1/4;
    width: 100vw;
    height: 100%;
    background: rgb(var(--dark-bg-color));
    overflow: hidden;
    overflow-y: auto;
}

.background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    z-index: 1;
}