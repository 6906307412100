.upcoming_connections {
    --upcoming-connection-gap: 49px;
}

.upcoming_connections {
    width: 100%;
    max-width: 1680px;
    height: max-content;
    padding: 0 var(--discovery-page-padding);
    display: grid;
    justify-items: flex-start;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: max-content;
    gap: var(--upcoming-connection-gap);
}

@media(max-width: 1680px) {
    .upcoming_connections {
        --upcoming-connection-gap: calc(24px + (49 - 24) * ((100vw - 769px) / (1680 - 769)));
    }
}

@media(max-width: 768px) {
    .upcoming_connections {
        --upcoming-connection-gap: calc(14.6px + (24 - 14.6) * ((100vw - 501px) / (768 - 501)));
    }
    .upcoming_connections {
        grid-template-columns: 1fr 1fr;
    }
}

@media(max-width: 500px) {
    .upcoming_connections {
        grid-template-columns: 1fr;
        gap: 16px;
        justify-items: center;
    }
}