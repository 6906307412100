.event_overview {
    --image-width: 550px;
}

.event_overview {
    background: transparent;
}

.event_overview>div {
    width: 100vw;
    max-width: 100vw;
    height: 100vh;
    border-radius: 0;
    background: rgba(var(--secondary-bg-color));
    opacity: 0.95;
    text-align: left;
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-template-rows: max-content max-content 1fr;
    row-gap: 12px;
    column-gap: 24px;
    overflow: hidden;
}

.image {
    grid-row: 2/4;
    width: var(--image-width);
}

.header {
    grid-row: 2;
    grid-column: 2;
    max-width: 568px;
    font:var(--font-h4);
    font-size: 32px;
    line-height: 38px;
    color: rgba(var(--primary-text-color));
}

.details {
    grid-row: 3;
    grid-column: 2;
    max-width: 568px;
    padding-right: 15px;
    font: var(--font-body-1);
    font-size: 16px;
    line-height: 24px;
    color: rgba(var(--primary-text-color));
    overflow-y: auto;
}

.close_button {
    grid-row: 1;
    grid-column: 2;
    justify-self: flex-end;
    border: none;
    background: transparent;
    padding-bottom: 38px;
}

.close_icon path {
    stroke: rgba(var(--primary-text-color));
}

@media(max-width: 1680px) {
    .event_overview {
        --image-width: calc(250px + (550 - 250) * ((100vw - 769px) / (1680 - 769)));
    }
}

@media(max-width: 768px) {
    .event_overview {
        --image-width: calc(275px + (471 - 275) * ((100vw - 375px) / (768 - 375))); 
    }

    .event_overview>div {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(3, max-content) 1fr;
    }
    .image {grid-row: 2;}
    .header {
        grid-row: 3;
        grid-column: 1;
        padding-top: 12px;
    }
    .details {
        grid-row: 4;
        grid-column: 1;
    }
    .close_button {grid-column: 1;}
}