.breakout_rooms {
    max-width: 280px;
    height: 100%;
    display: grid;
    row-gap: 20px;
    align-items: start;
    justify-items: start;
    grid-template-rows: repeat(6, max-content) 1fr max-content;
}

.title {
    font: var(--font-body-2);
    font-size: 16px;
    line-height: 24px;
}

.sub_title {
    font: var(--font-body-1);
    font-size: 16px;
    line-height: 24px;
}

.close_button {
    border: none;
    background: transparent;
}

.close_icon path {
    stroke: rgba(var(--primary-text-color));
}

.items {
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    display: grid;
    grid-auto-rows: max-content;
    row-gap: 16px;
}

.item {
    width: 100%;
    display: grid;
    column-gap: 10px;
    align-items: center;
    justify-items: flex-start;
    grid-template-columns: max-content 1fr;
    cursor: pointer;
}

.number_item {
    grid-template-columns: 1fr 50px;
}

.item input {
    width: 20px;
    height: 20px;
    padding-left: 5px;
    background: rgba(var(--tertiary-bg-color));
    border: 1px solid rgba(var(--primary-text-color));
}

.number_item input {
    width: 40px;
}

.item label {
    font: var(--font-body-1);
    font-size: 16px;
    line-height: 24px;
}

/* .item:last-of-type {
    margin-bottom: 40px;
} */

.auto_assign_button {
    width: 100%;
}

.all_rooms {
    width: 100%;
    height: 100%;
    padding-right: 10px;
    display: grid;
    grid-auto-rows: max-content;
    row-gap: 8px;
    overflow: hidden;
    overflow-y: auto;
}

.button {
    justify-self: center;
    width: 250px;
    text-transform: none;
}

.close {
    background: rgba(var(--primary-error-color));
}