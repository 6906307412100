.input {
    width: 100%;
    height: max-content;
    display: grid;
    grid-template-columns: 1fr max-content;
    gap: 8px;
}

.label {
    width: 100%;
    height: max-content;
    font: var(--font-body-1);
    font-size: 16px;
    line-height: 24px;
    color: rgba(var(--primary-text-color));
}

.counter {
    justify-self: flex-end;
    font: var(--font-body-1);
    font-size: 16px;
    line-height: 24px;
    color: rgba(var(--primary-text-color));
}

.input_field {
    grid-column: 1/3;
    width: 100%;
    height: 100px;
    background-color: rgba(var(--tertiary-bg-color));
    border: none;
    outline: none;
    resize: none;
    padding: 15px;
    font: var(--font-subtitle-2);
    font-size: 20px;
    line-height: 24px;
    color: rgba(var(--primary-text-color));
}

.input_field::placeholder {
    color: rgba(var(--primary-text-color));
    opacity: .7;
}

.input_field:disabled {opacity: .3;}

.sub_label {
    grid-column: 1/3;
    width: 100%;
    height: max-content;
    font: var(--font-body-1);
    font-size: 16px;
    line-height: 24px;
    color: rgba(var(--primary-text-color));
}

.error_input {
    background-color: rgba(var(--secondary-error-color));
    border: 1px solid rgba(var(--primary-error-color));
}

.error {
    width: 100%;
    font: var(--font-body-1);
    font-size: 18px;
    color: rgba(var(--primary-error-color));
}