.play_button {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: rgba(var(--primary-cta-color));
    background-position: center;
    transition: all 0.8s;
    cursor: pointer;
    z-index: 1;
    display: grid;
    justify-items: center;
    align-items: center;
}

.play_button:hover {
    background: rgba(var(--primary-cta-color)) radial-gradient(circle, transparent 1%, rgba(var(--primary-cta-color)) 1%) center/15000%;
}

.play_button:active {
    background-color: rgba(var(--accesories-bg-color));
    background-size: 100%;
    transition: all 0s;
}

.play_button::after {
    content: "";
    margin-left: 10px;
    border-top: 20px solid transparent;
    border-left: 30px solid rgba(var(--secondary-bg-color));
    border-bottom: 20px solid transparent;
}

.alternate {
    background-color: rgba(var(--secondary-bg-color));
}

.alternate:hover {
    background: rgba(var(--secondary-bg-color)) radial-gradient(circle, transparent 1%, rgba(var(--secondary-bg-color)) 1%) center/15000%;
}

.alternate:active {
    background-color: rgba(var(--accesories-bg-color));
}

.alternate::after {
    border-left: 30px solid rgba(var(--primary-cta-color));
}

@media(max-width: 700px) {
    .play_button {
        width: 60px;
        height: 60px;
    }
    .play_button::after {
        margin-left: 5px;
        border-top: 10px solid transparent;
        border-left: 20px solid rgba(var(--secondary-bg-color));
        border-bottom: 10px solid transparent;
    }
    .alternate::after {
        border-left: 20px solid rgba(var(--primary-cta-color));
    }
}