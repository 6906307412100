.text_input {
    width: 100%;
    height: max-content;
    display: grid;
    row-gap: 8px;
}

.label {
    width: 100%;
    height: max-content;
    font: var(--font-body-1);
    font-size: 16px;
    line-height: 24px;
    color: rgba(var(--primary-text-color));
}

.input {
    width: 100%;
    height: 56px;
    background-color: rgba(var(--tertiary-bg-color));
    border: none;
    padding: 15px;
    font: var(--font-subtitle-2);
    font-size: 20px;
    line-height: 24px;
    color: rgba(var(--primary-text-color));
}

.input::placeholder {
    color: rgba(var(--primary-text-color));
    opacity: .7;
}

.input:disabled {opacity: .3;}

.error_input {
    background-color: rgba(var(--secondary-error-color));
    border: 1px solid rgba(var(--primary-error-color));
}

.error {
    width: 100%;
    font: var(--font-body-1);
    font-size: 18px;
    color: rgba(var(--primary-error-color));
}