.sidebar_layout {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    width: max-content;
    height: 100%;
    overflow: hidden;
    padding: 10px 5px 10px 20px;
    background-color: rgba(var(--tertiary-bg-color));
    animation: appear 0.3s ease 0s 1 normal none;
    display: grid;
    row-gap: 20px;
    align-items: start;
    justify-items: start;
    grid-template-rows: max-content max-content 1fr;
}

@keyframes appear {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.hide {
    right: -380px;
}

.close_button {
    border: none;
    background: transparent;
}

.close_icon path {
    stroke: rgba(var(--primary-text-color));
}

.title {
    font: var(--font-body-2);
    font-size: 16px;
    line-height: 24px;
}

.contents {
    width: 100%;
    height: 100%;
    padding-right: 15px;
    overflow: hidden;
    overflow-y: auto;
    display: grid;
    grid-auto-rows: max-content;
    row-gap: 10px;
}