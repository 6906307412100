.waiting_room {
    --width: var(--video-feed-width);
    --row-gap: calc(5px + (24 - 5) * ((var(--width) - 375px) / (1680 - 375)));
    --column-gap: calc(10px + (24 - 10) * ((var(--width) - 375px) / (1680 - 375)));
    --padding: calc(10px + (30 - 10) * ((var(--width) - 375px) / (1680 - 375)));
    --button-height: calc(20px + (40 - 20) * ((var(--width) - 375px) / (1680 - 375))) !important;
    --button-font-size: calc(8px + (14 - 8) * ((var(--width) - 375px) / (1680 - 375))) !important;
}

.waiting_room {
    grid-row: 3;
    grid-column: 2;
    width: 100%;
    height: 100%;
    padding: var(--padding) 30px;
    display: grid;
    row-gap: var(--row-gap);
    grid-template-columns: 1fr;
    grid-template-rows: max-content 1fr;
}

.countdown {
    grid-row: 1;
    grid-column: 1 !important;
    justify-self: flex-start;
    padding: 0px !important;
    row-gap: var(--row-gap) !important;
    --title-font-size: calc(10px + (40 - 10) * ((var(--width) - 375px) / (1680 - 375))) !important;
    --subtitle-font-size: calc(8px + (25 - 8) * ((var(--width) - 375px) / (1680 - 375))) !important;
}

.countdown :nth-child(2) {
    padding-bottom: 0px !important;
}

.countdown :last-child {
    --number-font-size: calc(8px + (40 - 8) * ((var(--width) - 375px) / (1680 - 375))) !important;
    --unit-font-size: calc(4px + (16 - 4) * ((var(--width) - 375px) / (1680 - 375))) !important;
    column-gap: var(--column-gap);
}

.countdown :last-child div {
    column-gap: calc(var(--column-gap) - 5px);
}

.buttons {
    display: grid;
    column-gap: 10px;
    grid-template-columns: max-content max-content;
}

.button {
    color: rgba(var(--primary-text-color));
    background: rgba(var(--primary-cta-dark-color));
    height: var(--button-height);
    font-size: var(--button-font-size);
}

@media(max-width: 900px) {
    .countdown :last-child {
        grid-auto-columns: max-content !important;
    }
    .countdown :last-child div {
        row-gap: 2px;
    }
}