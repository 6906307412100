.error_notifications {
    position: fixed;
    left: 0px;
    bottom: 0px;
    width: 417px;
    height: max-content;
    padding: 10px 50px 50px 10px;
    overflow: hidden;
    pointer-events: none;
    z-index: 1000;
}