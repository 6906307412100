.spotlight_view {
    width: 100%;
    height: 66.04%;
    display: grid;
    justify-items: center;
    align-items: center;
}

.spotlight_view_1 {
    width: 70%;
    height: 70%;
}

.spotlight_view_2 {
    width: 100%;
    height: 70.96%;
    column-gap: 1.28%;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(2, 49.36%);
}

.spotlight_view_3 {
    width: 100%;
    height: 60%;
    column-gap: 1.82%;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(3, 32.12%);
}

.spotlight_view_4 {
    width: 78.05%;
    height: 100%;
    row-gap: 3.48%;
    column-gap: 2.33%;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);
}