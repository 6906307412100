.loading {
    grid-row: 2;
    width: 100vw;
    height: 100vh;
    display: flex;
    row-gap: 10px;
    align-items: center;
    justify-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(var(--primary-bg-color));
}

.message {
    font-weight: 500;
    font-size: 25px;
    color: rgba(var(--primary-text-color));
    text-align: center;
}

.icon path {
    fill: rgba(var(--active-color));
}