.org_button {
    width: 200px;
    height: 200px;
    padding: 0 10px;
    background: #FFFFFF;
    box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    display: grid;
    justify-items: center;
    align-items: center;
    text-align: center;
    font-family: 'Fira Sans Condensed';
    font-weight: 500;
    font-size: 24px;
    text-transform: capitalize;
}