.learn_more {
    background: transparent;
}

.learn_more>div {
    width: 100vw;
    max-width: 100vw;
    height: 100vh;
    border-radius: 0;
    background: rgba(var(--secondary-bg-color));
    opacity: 0.95;
    text-align: left;
    display: grid;
    grid-template-rows: max-content max-content 1fr;
    row-gap: 12px;
    overflow: hidden;
}

.header {
    font:var(--font-h4);
    font-size: 32px;
    line-height: 38px;
    color: rgba(var(--primary-text-color));
}

.details {
    max-width: 568px;
    padding-right: 15px;
    font: var(--font-body-1);
    font-size: 16px;
    line-height: 24px;
    color: rgba(var(--primary-text-color));
    overflow-y: auto;
}

.close_button {
    grid-row: 1;
    justify-self: flex-end;
    border: none;
    background: transparent;
    padding-bottom: 38px;
}

.close_icon path {
    stroke: rgba(var(--primary-text-color));
}