.timer {
    --number-font-size: 48px;
    --unit-font-size: 24px;
}

.sub_title {
    font: var(--font-subtitle-2);
    font-size: var(--subtitle-font-size);
    text-transform: capitalize;
    color: rgba(var(--countdown-text-color));
}

.timer {
    display: grid;
    grid-auto-flow: column;
    column-gap: 24px;
    grid-auto-columns: max-content;
}

.count {
    display: grid;
    grid-template-columns: max-content max-content;
    column-gap: 16px;
}

.number {
    width: calc(var(--number-font-size) * 1.7);
    height: calc(var(--number-font-size) * 1.7);
    font: var(--font-h3);
    font-size: var(--number-font-size);
    line-height: calc(var(--number-font-size) + 10px);
    text-align: center;
    color: rgba(var(--countdown-text-color));
    border: 1px solid rgba(var(--countdown-text-color));
    display: grid;
    justify-items: center;
    align-items: center;
}

.unit {
    align-self: center;
    font: var(--font-h6);
    font-size: var(--unit-font-size);
    line-height: calc(var(--unit-font-size) + 4px);
    color: rgba(var(--countdown-text-color));
    margin-bottom: 5px
}

@media(max-width: 900px) {
    .timer {
        grid-auto-columns: 1fr;
    }
    .count {
        grid-template-columns: 1fr;
        row-gap: 8px;
    }
}

@media(max-width: 768px) {
    .timer {
        --number-font-size: calc(26px + (48 - 26) * ((100vw - 375px) / (768 - 375)));
        --unit-font-size: calc(16px + (24 - 16) * ((100vw - 375px) / (768 - 375)));
    }
}