.participant {
    width: 100%;
    height: max-content;
    overflow: hidden;
    display: grid;
    align-items: center;
    column-gap: 10px;
    grid-template-columns: max-content 1fr max-content max-content;
}

.profile {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.name {
    font: var(--font-body-1);
    font-size: 14px;
    line-height: 18px;
    color: rgba(var(--primary-text-color));
    text-transform: capitalize;
}

.raise_hand {
    width: 25px;
    height: 25px;
    border: none;
    border-radius: 50%;
    background-color: rgba(var(--primary-text-color));
    display: grid;
    justify-items: center;
    align-items: center;
}

.spotlight {
    width: 25px;
    height: 25px;
    border: none;
    background-color: transparent;
    display: grid;
    justify-items: center;
    align-items: center;
}

.active_spotlight .icon path {
    fill: rgba(var(--active-color));
}

.active_raise_hand {
    background-color: rgba(var(--active-color));
}