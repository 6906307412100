.notification {
    padding-left: 10px;
    margin-bottom: 10px;
    background: rgba(var(--tertiary-bg-color));
    box-shadow: 0px 0px 6px 0px rgb(0 0 0 / 23%);
    border-radius: 8px 0px 0px 8px;
    cursor: pointer;
    pointer-events: all;
    display: grid;
    row-gap: 5px;
    column-gap: 10px;
    align-items: center;
    grid-template-columns: max-content 1fr;
}

.avatar {
    width: 34px;
    height: 34px;
    object-fit: cover;
    border-radius: 50%;
}

.bubble {
    padding: 10px;
    border-radius: 4px;
    background: rgba(var(--tertiary-bg-color));
    box-shadow: 0px 0px 12px rgb(0 0 0 / 13%);
    display: grid;
    gap: 5px;
    grid-template-columns: 1fr;
    grid-template-rows: max-content 1fr;
}

.name {
    grid-row: 1;
    grid-column: 1;
    font: var(--font-body-1);
    font-size: 16px;
    line-height: 24px;
    color: rgba(var(--active-color));
    text-transform: capitalize;
}

.content {
    grid-row: 2;
    grid-column: 1/3;
    font: var(--font-body-1);
    font-size: 16px;
    line-height: 24px;
    color: rgba(var(--primary-text-color));
    overflow-wrap: break-word;
}