.previous_connections {
    --previous-connection-row-gap: 60px;
    --previous-connections-list-gap: 25px;
    --previos-connection-padding-B: 72px;
    --prev-next-WH: 50px;
    --prev-next-margin-T: 85px;
}

.previous_connections {
    width: 100%;
    max-width: 1680px;
    height: max-content;
    padding: 0 var(--discovery-page-padding) var(--previos-connection-padding-B) var(--discovery-page-padding);
    display: grid;
    grid-template-columns: max-content 1fr max-content;
    grid-template-rows: repeat(2,max-content);
    row-gap: var(--previous-connection-row-gap);
    column-gap: 16px;
}

.previous_connection_title {
    grid-column: 1/4;
    font: var(--font-h4);
    font-size: 32px;
    line-height: 38px;
    color: rgba(var(--primary-text-color));
}

.prev,.next {
    width: var(--prev-next-WH);
    height: var(--prev-next-WH);
    margin-top: var(--prev-next-margin-T);
    display: grid;
    justify-items: center;
    align-items: center;
    border: none;
    border-radius: 100px;
    background: rgba(var(--primary-cta-color));
}

.prev_icon, .next_icon {
    width: 13px;
    height: 23px;
}
.prev_icon {transform: rotate(-90deg);}
.prev_icon path {stroke: rgba(var(--secondary-bg-color));}
.next_icon {transform: rotate(90deg);}
.next_icon path {stroke: rgba(var(--secondary-bg-color));}
.loading {
    width: 23px;
    height: 23px;
    animation: rotateWithBoost 1.5s linear infinite;
}
.loading path {fill: rgba(var(--secondary-bg-color));}

@keyframes rotateWithBoost {
    0% {transform: rotate(0deg);}
    100% {transform: rotate(360deg);}
}

.connections_list {
    padding-bottom: 20px;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    grid-template-rows: max-content;
    gap: var(--previous-connections-list-gap);
    justify-items: flex-start;
    align-items: flex-start;
}

@media(max-width: 1680px) {
    .previous_connections {
        --previous-connection-row-gap: calc(24px + (60 - 24) * ((100vw - 769px) / (1680 - 769)));
        --previous-connections-list-gap: calc(16px + (25 - 16) * ((100vw - 769px) / (1680 - 769)));
        --previos-connection-padding-B: calc(40px + (72 - 40) * ((100vw - 769px) / (1680 - 769)));
        --prev-next-WH: calc(40px + (50 - 40) * ((100vw - 769px) / (1680 - 769)));
        --prev-next-margin-T: calc(26px + (85 - 26) * ((100vw - 769px) / (1680 - 769)));
    }
}

@media(max-width: 768px) {
    .previous_connections {
        --previous-connection-row-gap: calc(16px + (24 - 16) * ((100vw - 376px) / (768 - 376)));
        --previous-connections-list-gap: calc(12px + (16 - 12) * ((100vw - 376px) / (768 - 376)));
        --previos-connection-padding-B: calc(32px + (40 - 32) * ((100vw - 376px) / (768 - 376)));
    }
    .connections_list {
        grid-column: 1/4;
        overflow: hidden;
        overflow-x: auto;
    }
    .prev,.next{display: none;}
}

@media(max-width: 375px) {
    .previous_connections {
        --previous-connection-row-gap: 16px;
    }
}