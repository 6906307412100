.form {
    width: 100%;
    height: 100%;
    display: grid;
    row-gap: 24px;
}

.title {
    font: var(--font-h4);
    font-size: 32px;
    line-height: 38px;
    color: rgba(var(--primary-text-color));
}

.forgot_password_button {
    width: max-content;
    border: none;
    background: none;
    text-decoration: underline;
    font: var(--font-body-1);
    font-size: 16px;
    line-height: 24px;
    color: rgba(var(--primary-text-color));
}

.error_message {
    width: 100%;
    height: max-content;
    font: var(--font-body-1);
    font-size: 18px;
    background: rgba(var(--primary-error-color));
    color: rgba(var(--primary-text-dark-color));
    border-radius: 2px;
    padding: 10px 10px;
}

.tc_privacy {
    display: grid;
    gap: 8px;
    grid-template-columns: max-content 1fr;
}

.tc_checkbox {
    grid-row: 1;
    grid-column: 1;
    width: 23px;
    height: 23px;
    margin-top: 5px;
    background: rgba(var(--tertiary-bg-color));
    border: 1px solid rgba(var(--primary-text-color));
    -moz-appearance: none;
    -webkit-appearance: none;
    -o-appearance: none;
}

.tc_checkbox:checked {
    -moz-appearance: checkbox;
    -webkit-appearance: checkbox;
    -o-appearance: checkbox;
}

.not_checked {
    background-color: rgba(var(--secondary-error-color));
    border: 1px solid rgba(var(--primary-error-color));
}

.tc_text {
    grid-row: 1;
    grid-column: 2;
    font: var(--font-body-1);
    font-size: 16px;
    line-height: 24px;
    color: rgba(var(--primary-text-color));
}

.tc_text a {
    font: var(--font-body-2);
    font-size: 16px;
    line-height: 24px;
    color: rgba(var(--primary-text-color));
    text-decoration: none;
}

.tc_error {
    grid-row: 2;
    grid-column: 1/3;
    width: 100%;
    font: var(--font-body-1);
    font-size: 18px;
    color: rgba(var(--primary-error-color));
}

.register_success {
    display: grid;
    gap: 8px;
    justify-items: center;
    align-items: center;
}

.celebrate {
    width: 118px;
    height: 118px;
    border-radius: 100px;
    background-color: var(--icon-avatar-background-color);
    display: grid;
    justify-items: center;
    align-items: center;
}

.icon {
    width: 64px;
}

.text {
    font: var(--font-h6);
    font-size: 24px;
    line-height: 28px;
    color: rgba(var(--primary-text-color));
}

.or {
    font-family: Noir No1;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #171831;
}

.google {
    background-color: transparent;
    display: grid;
    grid-auto-flow: column;
    column-gap: 15px;
    padding: 10px 10px 10px 0px;
    font-family: Noir No1;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #171831;
    text-transform: none;
}

.google:active {
    background: transparent !important;
    opacity: 0.5;
}

.google_icon {
    width: 40px;
    background-color: white;
    border-radius: 10px;
}