:root {
    --padding: 16px;
}

.modal {
    width: 100vw;
    height: 100vh;
    background: rgba(var(--dark-bg-color));
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    display: grid;
    justify-items: center;
    align-items: center;
}

.upload_photo {
    width: max-content;
    /* background-color: rgb(0, 0, 0); */
    border-radius: 4px;
    display: grid;
    justify-items: center;
    align-items: center;
}

.header {
    grid-row: 1;
    grid-column: 1;
    width: 100%;
    height: 60px;
    padding: 0px 20px;
    display: grid;
    grid-template-columns: 1fr max-content;
    align-items: center;
}

.title {
    height: max-content;
    font: var(--font-subtitle-1);
    color: rgba(var(--primary-text-dark-color));
}

.close_modal {
    justify-self: flex-end;
    width: 20px;
    height: 20px;
    background-color: transparent;
    border: none;
}

.close_icon path {
    stroke: rgba(var(--primary-text-dark-color));
}

.image_cropper {
    grid-row: 2;
    grid-column: 1;
    width: 100%;
    height: max-content;
    padding: 0 var(--padding);
    /* background-color: rgb(0, 0, 0); */
    display: grid;
    grid-auto-flow: row;
    justify-items: center;
}

.cropper_js {
    max-width: 18rem;
    max-height: 18rem;
    margin-bottom: 1rem;
}

.slider {
    -webkit-appearance: none;
    appearance: none;
    width: 90%;
    height: 1px;
    background: rgba(var(--tertiary-bg-color));
    outline: none;
    opacity: 0.7;
    margin-bottom: 1rem;
}

.slider:hover {
    opacity: 0.9;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 12px;
    height: 12px;
    background: rgba(var(--active-color));
    border-radius: 50%;
}

.slider::-moz-range-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 12px;
    height: 12px;
    background: rgba(var(--active-color));
    border-radius: 50%;
}

.footer {
    width: 100%;
    grid-row: 3;
    grid-column: 1;
    display: grid;
    grid-template-columns: max-content max-content;
    justify-content: center;
    column-gap: 30px;
    padding: 10px 20px;
}

.delete_photo {
    grid-row: 1;
    grid-column: 1;
    justify-self: center;
    font-size: 15px;
    color: rgba(var(--primary-text-dark-color));
    background: none;
    text-decoration: underline;
}

.apply_button {
    grid-row: 1;
    grid-column: 3;
    justify-self: flex-start;
    color: rgba(var(--primary-text-color));
    background: rgba(var(--primary-cta-dark-color));
    font-size: 15px;
    text-align: center;
}

@media(max-width: 600px) {
    :root {
        --padding: calc(5px + (16 - 5) * ((100vw - 320px) / (600 - 320)));
    }
}