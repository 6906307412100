.landing_page {
    --header-height: 642px;
    --title-font-size: 64px;
}
.landing_page {
    width: 100vw;
    height: 100%;
    display: grid;
    grid-template-rows: var(--header-height) max-content 1fr;
    gap: 20px;
    justify-items: center;
    align-content: flex-start;
}
.title {
    width: 100%;
    padding-left: 36px;
    font-family: 'Fira Sans Condensed';
    font-weight: 500;
    font-size: var(--title-font-size);
    line-height: calc(var(--title-font-size) + 12px);
}

.buttons {
    width: max-content;
    height: 100%;
    padding: 20px 20px;
    display: grid;
    grid-template-columns: repeat(2, max-content);
    gap: 20px;
    overflow: hidden;
    overflow-y: auto;
}

@media(max-width: 1440px) {
    .landing_page {
        --header-height: calc(156px + (642 - 156) * ((100vw - 375px) / (1440 - 375)));
        --title-font-size: calc(39px + (64 - 39) * ((100vw - 769px) / (1440 - 769)));
    }
}

@media(max-width: 470px) {
    .buttons {
        grid-template-columns: max-content;
    }
}