.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #00000080;
    display: grid;
    align-items: center;
    justify-items: center;
    z-index: 1000;
}

.modal {
    width: 90%;
    height: max-content;
    max-width: 500px;
    padding: 50px;
    border-radius: 10px;
    background-color: #F1F3F5;
    font-family: Lato;
    font-weight: 500;
    color: #2F2F2F;
    text-align: center;
}

.title {
    font-size: 24px;
}

.text {
    font-size: 18px;
}

.button {
    width: max-content;
    min-width: 180px;
    height: 36px;
    padding: 0px 15px;
    background: #E1EDCC;
    border-radius: 2px;
    font-family: Lato;
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    color: #545050;
    border: none;
    filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.25));
}

.red {
    background: #C0372A;
    color: #FFFFFF;
}