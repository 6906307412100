.participant {
    width: max-content;
    height: max-content;
    padding: 4px 8px;
    border: 1px solid rgba(var(--primary-text-color));
    border-radius: 32px;
    background: transparent;
    display: grid;
    grid-template-columns: max-content 1fr max-content;
    column-gap: 8px;
    align-items: center;
}

.profile_image {
    width: 24px;
    height: 24px;
    border-radius: 100px;
    object-fit: cover;
}

.name {
    font: var(--font-body-1);
    font-size: 14px;
    line-height: 18px;
    color: rgba(var(--primary-text-color));
    text-transform: capitalize;
}

.remove_button {
    border: none;
    background: transparent;
}

.icon {
    width: 12px;
    height: 12px;
}

.icon path {
    stroke: rgba(var(--primary-text-color));
}