.notification {
    padding-left: 10px;
    margin-bottom: 16px;
    background: rgba(var(--primary-bg-color));
    box-shadow: 0px 0px 6px 0px rgb(0 0 0 / 23%);
    border-radius: 8px;
    cursor: pointer;
    pointer-events: all;
    display: grid;
    row-gap: 5px;
    column-gap: 10px;
    align-items: center;
    grid-template-columns: max-content 1fr;
}

.notification:last-child {
    margin-bottom: 0px;
}

.message {
    padding: 10px;
    border-radius: 4px;
    background: rgba(var(--primary-bg-color));
    box-shadow: 0px 0px 12px rgb(0 0 0 / 13%);
    font: var(--font-body-1);
    text-transform: capitalize;
}

.icon {
    width: 34px;
    height: 34px;
    border-radius: 50%;
}

.icon path {
    fill: rgba(var(--primary-error-color));
}