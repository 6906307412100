.selfie_view {
    --width: var(--video-feed-width);
    --selfie-view-height: calc((9 / 16) * var(--selfie-view-width));
    --selfie-view-width: calc(90px + (700 - 90) * ((var(--width) - 375px) / (1680 - 375)));
    --selfie-error-font-size: calc(10px + (16 - 10) * ((var(--width) - 375px) / (1680 - 375)));
}

.selfie_view {
    align-self: flex-end;
    width: var(--selfie-view-width);
    height: var(--selfie-view-height);
    background: #808080;
    display: grid;
    align-items: center;
    justify-items: center;
}

.video {
    grid-row: 1;
    grid-column: 1;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.hide {
    display: none;
}

.avatar {
    grid-row: 1;
    grid-column: 1;
    width: 30%;
    border-radius: 50%;
    object-fit: cover;
}

.error {
    grid-row: 1;
    grid-column: 1;
    width: 100%;
    height: 100%;
    background-color: #000000b8;
    font-size: var(--selfie-error-font-size);
    color: #ffffff;
    display: grid;
    column-gap: 10px;
    align-content: center;
    align-items: center;
    justify-content: center;
    justify-items: center;
    z-index: 1;
}

.error>p {
    font: var(--font-body-1);
    color: rgba(var(--primary-text-dark-color));
    text-align: center;
}

.disabled_button {
    background: rgba(var(--primary-error-color)) !important;
}