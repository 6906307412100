.breakout_room {
    width: 100%;
    height: max-content;
    display: grid;
    grid-auto-rows: max-content;
    grid-template-columns: max-content max-content 1fr;
    gap: 8px;
    align-items: center;
    overflow: hidden;
    transition: all 0.3s;
}

.collapsed {
    height: 30px;
}

.open_close_button {
    border: none;
    background: transparent;
    display: grid;
}

.icon {
    width: 10px;
    height: 10px;
    transition: all 0.3s;
}

.collapsed_icon {
    transform: rotate(180deg);
}

.icon path {
    stroke: rgba(var(--primary-text-color));
}

.join_button {
    height: 30px;
    justify-self: flex-end;
}

.title {
    font: var(--font-body-2);
    font-size: 16px;
    line-height: 24px;
}

.assigned_participants {
    grid-column: 1/4;
    width: 100%;
    height: max-content;
    min-height: 100px;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    transition: all 0.1s;
}

.drop_active {
    border-radius: 2px;
    background-color: rgba(var(--active-color));
}