.banner {
    --max-width: 2100px;
    --height: 459px;
    --padding-RL: 56px;
}

@media(max-width: 2100px) {
    .banner {
        --max-width: 100%;
    }
}

.banner {
    width: 100vw;
    height: max-content;
    display: grid;
    justify-items: center;
    align-items: center;
    grid-template-columns: 1fr var(--max-width) 1fr;
}

.banner {
    grid-row: 1;
    grid-column: 1/4;
    content: '';
    width: 100%;
    height: 100%;
    background: linear-gradient(89.96deg, rgb(var(--overlay-img-4-color)) 22.37%, rgba(var(--overlay-img-4-color), 0.55) 99.5%);
}

.image {
    grid-row: 1;
    grid-column: 2;
    justify-self: flex-end;
    width: 100%;
    height: var(--height);
    object-fit: cover;
    z-index: -1;
}

@media(max-width: 768px) {
    .banner {
        --height: calc(320px + (459 - 320) * ((100vw - 375px) / (768 - 375)));
    }
}