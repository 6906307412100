.footer {
    grid-row: 5;
    grid-column: 1/4;
    width: 100%;
    height: 60px;
    padding: 0px 20px;
    background-color: rgba(var(--dark-bg-color));
    display: grid;
    align-items: center;
    grid-auto-flow: max-content;
    justify-content: space-between;
}