.profile_page{
    --profile-page-padding: 56px;
}

.profile_page {
    width: 100%;
    height: 100%;
    padding: var(--profile-page-padding);
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 2fr;
    gap: 30px;
}

.avatar {
    width: 100%;
    display: grid;
    grid-auto-rows: max-content;
    row-gap: 8px;
    justify-items: center;
    align-items: center;
}

.image_edit {
    position: relative;
    width: max-content;
}

.profile_image {
    width: 200px;
    border-radius: 100px;
    object-fit: cover;
    filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.05));
}

.name {
    width: 100%;
    font: var(--font-h6);
    font-size: 24px;
    font-weight: 700;
    color: rgba(var(--primary-text-color));
    text-align: center;
    text-transform: capitalize;
}

.email {
    width: 100%;
    font: var(--font-body-1);
    font-size: 16px;
    color: rgba(var(--secondary-text-color));
    text-align: center;
    word-break: break-all;
}

.button {
    background: transparent;
    border: none;
}

.edit_photo {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 48px;
    height: 48px;
    border-radius: 100px;
    padding: 12px;
    background: rgba(var(--secondary-bg-color));
}

.icon {
    width: 24px;
    height: 24px;
}

.icon path {
    fill: rgba(var(--primary-text-color));
}

.change_pw {
    grid-column: 2;
    grid-row: 3;
    justify-self: flex-start;
    text-decoration: underline;
    font: var(--font-body-2);
    font-size: 16px;
    line-height: 24px;
}

@media(max-width: 1023px) {
    .profile_page {
        --profile-page-padding: calc(10px + (32 - 10) * ((100vw - 320px) / (1023 - 320)));
    }
}

@media(max-width: 600px) {
    .profile_page {
        grid-auto-flow: row;
        grid-template-columns: 1fr;
        grid-auto-rows: max-content;
    }
    .avatar {justify-items: flex-start;}
    .name { text-align: left;}
    .email {text-align: left;}
    .change_pw {grid-column: 1;}
}