.modal {
    background: rgba(var(--secondary-bg-color));
    opacity: 0.95;
    overflow: hidden;
    overflow-y: auto;
}

.modal>div {
    background: transparent;
}

.upload_photo {
    width: 100%;
    max-width: 425px;
    height: 100%;
    display: grid;
    grid-auto-rows: max-content;
    row-gap: 24px;
    justify-items: center;
}

.title {
    font: var(--font-h4);
    font-size: 32px;
    line-height: 38px;
    color: rgba(var(--primary-text-color));
}

.close_button {
    justify-self: flex-end;
    border: none;
    background: transparent;
}

.close_icon path {
    stroke: rgba(var(--primary-text-color));
}

.image_edit {
    position: relative;
    width: max-content;
}

.profile_image {
    width: 332px;
    border: 2px solid rgba(var(--primary-text-dark-color));
    border-radius: 166px;
    background: rgba(var(--dark-bg-color));
    object-fit: cover;
    mix-blend-mode: normal;
}

.icon_button {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 80px;
    height: 80px;
    border-radius: 100px;
    padding: 20px;
    background: rgba(var(--dark-bg-color));
    border: none;
}

.icon {
    width: 40px;
    height: 40px;
}

.icon path {
    fill: rgba(var(--primary-text-dark-color));
}

.progress_bar_holder {
    width: 100%;
    height: 5px;
    border: none;
    border-radius: 4px;
    background: rgba(var(--tertiary-bg-color));
}

.progress_bar {
    height: 100%;
    border-radius: 4px;
    background: rgba(var(--active-color));
}

.buttons {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    gap: 24px;
}

.error_message {
    width: 100%;
    height: max-content;
    font: var(--font-body-1);
    font-size: 18px;
    background: rgba(var(--primary-error-color));
    color: rgba(var(--primary-text-dark-color));
    border-radius: 2px;
    padding: 10px 10px;
}