.guest {
    max-height: 350px;
    display: grid;
    row-gap: 12px;
    column-gap: 16px;
    grid-template-columns: max-content 1fr;
    grid-template-rows: max-content 1fr;
    justify-items: center;
    align-items: flex-start;
}

.image {
    grid-row: 1/3;
    width: 64px;
    height: 64px;
    object-fit: cover;
    border-radius: 50%;
}

.name {
    justify-self: flex-start;
    font: var(--font-h6);
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: rgba(var(--primary-text-color));
    text-transform: capitalize;
}

.info {
    height: 100%;
    padding-right: 20px;
    font: var(--font-body-1);;
    font-size: 16px;
    line-height: 24px;
    color: rgba(var(--primary-text-color));
    overflow: hidden;
    overflow-y: auto;
}

@media(max-width: 768px) {
    .guest {
        max-height: 200px;
    }
}