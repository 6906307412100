.header {
    --header-gap: 20px;
    --event-page-logo-height: 48px;
    --button-font-size: 16px;
}

.header {
    grid-row: 1;
    grid-column: 1/4;
    width: 100%;
    height: 80px;
    padding: 0px 20px;
    background-color: rgba(var(--dark-bg-color));
    display: grid;
    align-items: center;
    justify-items: center;
    grid-auto-flow: column;
    grid-column-gap: var(--header-gap);
    column-gap: var(--header-gap);
    grid-template-columns: repeat(3, max-content) 1fr;
}

.separator {
    width: 1px;
    height: var(--event-page-logo-height);
    margin-left: 10px;
    background: rgb(var(--primary-text-dark-color));
}

.org_logo {
    height: var(--event-page-logo-height);
}

.logo {
    height: var(--event-page-logo-height);
    -webkit-filter: invert(100%);
    filter: invert(100%)
}

.info_panel {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 1fr;
    gap: 18px;
    justify-items: flex-end;
    align-items: center;
}

.menu {
    grid-row: 1;
    grid-column: 1;
    width: max-content;
    display: grid;
    grid-auto-flow: column;
    grid-auto-rows: max-content;
    column-gap: 20px;
}

.button {
    height: max-content;
    outline: none;
    border: none;
    background: transparent;
    color: rgba(var(--primary-text-dark-color));
    font: var(--font-body-2);
    font-size: var(--button-font-size);
    text-transform: uppercase;
    display: grid;
    gap: 10px;
    align-items: center;
    grid-auto-flow: column;
}

.underling {
    font-weight: 700;
    border-bottom: 1px solid rgba(var(--primary-text-color));
}

.info_icon path {
    fill: rgba(var(--primary-text-color));
}

@media(max-width: 1200px) {
    .header {
        --event-page-logo-height: calc(30px + (48 - 30) * ((100vw - 769px) / (1200 - 769)));
    }
}

@media(max-width: 768px) {
    .header {
        --header-gap: calc(10px + (20 - 10) * ((100vw - 426px) / (768 - 426)));
        --event-page-logo-height: calc(25px + (48 - 25) * ((100vw - 426px) / (768 - 426)));
        --button-font-size: calc(12px + (16 - 12) * ((100vw - 426px) / (768 - 426)));
    }

    .menu {
        grid-auto-flow: row;
        justify-items: flex-end;
    }
}

@media(max-width: 425px) {
    .header {
        --event-page-logo-height: calc(18px + (25 - 18) * ((100vw - 375px) / (425 - 375)));
    }
}