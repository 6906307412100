.button {
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 50%;
    background: rgba(var(--overlay-bg-1-color));
    display: grid;
    align-items: center;
    justify-items: center;
    filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.25));
}

button:enabled:active {
    background: rgba(var(--active-color));
}

.active {
    background: rgba(var(--active-color));
}

.icon {
    height: 30px;
    width: 30px;
}

.icon path {
    fill: rgba(var(--dark-bg-color));
}

.loading {
    height: 20px;
    width: 20px;
}