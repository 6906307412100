.participant {
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 4px;
    background-color: black;
    filter: drop-shadow(1px 1px 6px #00000026);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.title {
    position: absolute;
    font: var(--font-body-1);
    font-size: 20px;
    line-height: 24px;
    color: white;
    display: grid;
    row-gap: 10px;
    justify-items: center;
    z-index: 0;
}

.icon {
    width: 30%;
}

.video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
    z-index: 1;
}