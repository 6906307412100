.avatar {
    position: relative;
    justify-self: flex-end;
}

.profile_image {
    width: 54px;
    height: 54px;
    cursor: pointer;
    object-fit: cover;
    border-radius: 100px;
    filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.05))
}

.profile_menu {
    position: absolute;
    right: 0;
    width: 272px;
    height: max-content;
    padding: 40px;
    display: grid;
    row-gap: 10px;
    background-color: rgba(var(--tertiary-bg-color));
    box-shadow: -4px -4px 40px rgba(0, 0, 0, 0.1);
}

.name {
    padding-bottom: 4px;
    font: var(--font-body-2);
    font-size: 18px;
    line-height: 24px;
    color: rgba(var(--secondary-text-color));
}

.button {
    border: none;
    outline: none;
    background: none;
    text-align: left;
    font: var(--font-body-2);
    color: rgba(var(--secondary-text-color));
    transition: all 300ms;
}

.button:hover {
    padding-left: 8px;
    background: rgba(var(--secondary-bg-color));
    color: rgba(var(--primary-text-color));
}