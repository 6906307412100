.community_page {
    --community-page-padding: 56px;
    --members-column-gap: 24px;
}

.community_page {
    width: 100vw;
    height: 100%;
    background: rgba(var(--primary-bg-color));
    display: grid;
    grid-template-rows: max-content max-content 1fr;
    row-gap: 56px;
}

.banner {
    grid-row: 1;
    width: 100%;
}

.title {
    grid-row: 2;
    width: 100%;
    padding: 0 var(--community-page-padding);
    font: var(--font-h4);
    font-size: 32px;
    line-height: 38px;
    color: rgba(var(--primary-text-color));
}

.members {
    grid-row: 3;
    padding: 0 var(--community-page-padding) var(--community-page-padding) var(--community-page-padding);
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: var(--members-column-gap);
    row-gap: 56px;
}

.loading {
    grid-row: 2;
    width: 100%;
    height: 100%;
}

@media(max-width: 1023px) {
    .community_page {
        --community-page-padding: calc(10px + (32 - 10) * ((100vw - 320px) / (1023 - 320)));
        --members-column-gap: calc(10px + (24 - 10) * ((100vw - 320px) / (1023 - 320)));
    }
}

@media(max-width: 768px) {
    .community_page {row-gap: 32px;}
    .members {grid-template-columns: repeat(2, 1fr);}
}

@media(max-width: 500px) {
    .community_page {row-gap: 24px;}
    .members {grid-template-columns: 1fr;}
}