.button {
    width: max-content;
    height: 40px;
    padding: 0 20px;
    background: rgba(var(--primary-cta-color));
    border: none;
    border-radius: 2px;
    font: var(--font-button);
    font-size: 14px;
    color: rgba(var(--primary-text-dark-color));
    text-transform: uppercase;
    display: grid;
    justify-items: center;
    align-items: center;
}