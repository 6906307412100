.controls {
    position: fixed;
    bottom: 10px;
    left: 50%;
    z-index: 2;
    margin-left: -144px;
    width: max-content;
    height: 44px;
    display: grid;
    align-items: center;
    justify-items: center;
    grid-auto-flow: column;
    grid-auto-columns: 65px;
}

.disabled_button {
    background: rgba(var(--primary-error-color)) !important;
}