.about_event {
    grid-column: 1;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-auto-rows: max-content;
    align-items: flex-start;
    gap: 16px;
}

.title {
    grid-column: 1;
    font: var(--font-subtitle-1);
    font-size: 20px;
    color: rgba(var(--primary-text-color));
}

.title_guest{
    grid-row: 1;
    grid-column: 2;
    font: var(--font-subtitle-1);
    font-size: 20px;
    color: rgba(var(--primary-text-color));
}

.details {
    max-width: 650px;
    grid-column: 1;
    font: var(--font-body-1);
    font-size: 16px;
    color: rgba(var(--primary-text-color));
}

.guests {
    display: grid;
    row-gap: 16px;
}

.guest {
    display: grid;
    grid-template-columns: max-content 1fr;
    column-gap: 16px;
}

.guest_image {
    grid-row: 1/3;
    grid-column: 1;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    object-fit: cover;
    filter: drop-shadow(1px 1px 6px rgba(0, 0, 0, 0.15));
}

.guest_name {
    grid-row: 1;
    grid-column: 2;
    align-self: flex-end;
    font: var(--font-body-2);
    font-size: 16px;
    color: rgba(var(--primary-text-color));
}

.guest_title {
    grid-row: 2;
    grid-column: 2;
    align-self: flex-start;
    font: var(--font-body-1);
    font-size: 16px;
    color: rgba(var(--secondary-text-color));
}

@media(max-width: 768px) {
    .about_event {
        grid-template-columns: 1fr;
    }
    .details {grid-row: 2;}
    .title_guest{
        grid-row: 3;
        grid-column: 1;
    }
}