.header {
    --img-height: 642px;
    --logo-height: 74px;
}

.header {
    position: relative;
    width: 100vw;
    height: max-content;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: max-content 1fr;
}

.header::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100vw;
    height: var(--img-height);
    background: rgba(243, 199, 22, 0.81);
}

.bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: var(--img-height);
}

.logo {
    position: absolute;
    left: 20px;
    top: 20px;
    grid-column: 1;
    height: var(--logo-height);
    z-index: 2;
}

@media(max-width: 1440px) {
    .header {
        --img-height: calc(156px + (642 - 156) * ((100vw - 375px) / (1440 - 375)));
        --logo-height: calc(48px + (74 - 48) * ((100vw - 769px) / (1440 - 769)));
    }
}

@media(max-width: 768px) {
    .header {
        --logo-height: calc(40px + (48 - 40) * ((100vw - 375px) / (768 - 375)));
    }
}