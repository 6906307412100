.upcoming_connection_card {
    --card-padding: 24px;
    --card-width: 490px;
    --image-height: calc((425.82 / 759) * var(--card-width));
    --title-font-size: 20px;
    --date-desc-button-font-size: 16px;
    --copy-meeting-url: 217, 220, 218, 1;/*#d9dcda*/;
}

.upcoming_connection_card {
    width: var(--card-width);
    height: 100%;
    border: 1px solid rgba(var(--secondary-bg-color));
    display: grid;
    grid-template-rows: var(--image-height) 1fr;
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background: #000;
}

.card_info {
    width: 100%;
    height: 100%;
    padding: var(--card-padding);
    box-sizing: border-box;
    display: grid;
    grid-template-rows: repeat(2, max-content) 1fr max-content;
    justify-items: flex-start;
}

.date {
    font: var(--font-body-1);
    font-size: var(--date-desc-button-font-size);
    line-height: calc(var(--date-desc-button-font-size) + 7px);
    color: rgba(var(--secondary-text-color));
    padding-bottom: 8px;
}

.title {
    font: var(--font-subtitle-1);
    font-size: var(--title-font-size);
    line-height: calc(var(--title-font-size) + 4px);
    color: rgba(var(--primary-text-color));
    padding-bottom: 4px;
}

.short_description {
    width: 100%;
    height: calc(6 * calc(var(--date-desc-button-font-size) + 7px));
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font: var(--font-body-1);
    font-size: var(--date-desc-button-font-size);
    line-height: calc(var(--date-desc-button-font-size) + 7px);
    color: rgba(var(--primary-text-color));
}

.learn_more_button {
    padding-top: 14px;
    background: transparent;
    border: none;
    font: var(--font-button);
    font-size: var(--date-desc-button-font-size);
    line-height: calc(var(--date-desc-button-font-size) + 7px);
    text-decoration: none;
    text-transform: uppercase;
    color: rgba(var(--primary-text-color));
}
.learn_more_button-custom {
    display: inline-flex;
}

@media(max-width: 1680px) {
    .upcoming_connection_card {
        --card-padding: calc(14px + (24 - 14) * ((100vw - 769px) / (1680 - 769)));
        --card-width: calc(284px + (490 - 284) * ((100vw - 1024px) / (1680 - 1024)));
        --title-font-size: calc(16px + (20 - 16) * ((100vw - 769px) / (1680 - 769)));
        --date-desc-button-font-size: calc(12px + (16 - 12) * ((100vw - 769px) / (1680 - 769)));
    }
}

@media(max-width: 1023px) {
    .upcoming_connection_card {
        --card-width: calc(224px + (298 - 224) * ((100vw - 769px) / (1023 - 769)));
    }
}

@media(max-width: 768px) {
    .upcoming_connection_card {
        --card-padding: calc(14px + (20 - 14) * ((100vw - 501px) / (768 - 501)));
        --card-width: calc(228px + (348 - 228) * ((100vw - 501px) / (768 - 501)));
        --title-font-size: calc(16px + (20 - 16) * ((100vw - 501px) / (768 - 501)));
        --date-desc-button-font-size: calc(12px + (16 - 12) * ((100vw - 501px) / (768 - 501)));
    }
}

@media(max-width: 500px) {
    .upcoming_connection_card {
        --card-padding: 20px;
        --card-width: 410px;
        --title-font-size: 20px;
        --date-desc-button-font-size: 16px;
    }
}

@media(max-width: 440px) {
    .upcoming_connection_card {
        --card-padding: calc(14px + (20 - 14) * ((100vw - 375px) / (440 - 375)));
        ;
        --card-width: calc(352px + (410 - 352) * ((100vw - 375px) / (440 - 375)));
        ;
        --title-font-size: calc(16px + (20 - 16) * ((100vw - 375px) / (440 - 375)));
        ;
        --date-desc-button-font-size: calc(12px + (16 - 12) * ((100vw - 375px) / (440 - 375)));
        ;
    }
}

.create_meeting_button {
    background: rgba(var(--highlight-bg-color));    
    border: none;
    color: rgba(var(--primary-text-color));
    display: inline-flex;
    justify-self: right;
    margin-top: -2rem;
}
.create_meeting_button:hover {
    background: rgba(var(--secondary-bg-color));    
}
.create_meeting_button:disabled {
    opacity: 0.7;
    pointer-events: none;
}

.copy_meeting_url_section {
    font: var(--font-button);
    font-size: var(--date-desc-button-font-size);
    line-height: calc(var(--date-desc-button-font-size) + 7px);
    text-decoration: none;
    color: rgba(var(--primary-text-color));
    background: rgba(var(--copy-meeting-url));    
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px auto;
    min-width: 376px;
    padding: 5px 7px 5px 10px;
    height: fit-content;
    word-break: break-word;
    align-items: center;
}
.copy_meeting_url_box{
    padding-right: 10px;
}
.copy_button {
    cursor: pointer;
    border-radius: 50%;
    padding: 10px;
}
.copy_button:hover {
    background: #afb2b0;
    color: rgba(var(--primary-text-dark-color));
}
.copy_button>svg {
    vertical-align: text-bottom;
}

.modal_buttons {
    width: 50%;
    margin: 20px auto 0 auto !important;
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr;
    justify-content: space-between;
    background: rgba(var(--primary-cta-color));
    border: none;
    border-radius: 2px;
    font: var(--font-button);
    font-size: 14px;
    color: rgba(var(--primary-text-dark-color)) !important;
}
.modal_buttons>button {
    width: 100%;
    text-transform: none;
    background: transparent;
    color: rgba(var(--primary-text-dark-color)) !important;
}

.green_button {
    background: rgba(76, 175, 80, 1);
}

.green_button:active, .green_button:hover {
    background: rgba(76, 175, 80, 0.9);
}

.copy_successfull {
    position: absolute;
    margin-top: 3.5rem;
    margin-left: -2.5rem;
    text-align: left;
    background: #000000ad;
    padding: 5px;
    width: fit-content;
    border-radius: 8px;
    color: rgba(var(--primary-text-dark-color));
}