.form {
    grid-row: 1/3;
    width: 100%;
    max-width: 425px;
    height: 100%;
    display: grid;
    grid-auto-rows: max-content;
    row-gap: 24px;
}

.title {
    font: var(--font-h4);
    font-size: 32px;
    line-height: 38px;
    color: rgba(var(--primary-text-color));
}

.error_message {
    width: 100%;
    height: max-content;
    font: var(--font-body-1);
    font-size: 18px;
    background: rgba(var(--primary-error-color));
    color: rgba(var(--primary-text-dark-color));
    border-radius: 2px;
    padding: 10px 10px;
}

@media(max-width: 600px) {
    .form {
        grid-row: 2;
    }
}