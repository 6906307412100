.form {
    width: 100%;
    height: 100%;
    display: grid;
    row-gap: 24px;
}

.title {
    font: var(--font-h4);
    font-size: 32px;
    line-height: 38px;
    color: rgba(var(--primary-text-color));
}

.forgot_password_button {
    width: max-content;
    border: none;
    background: none;
    text-decoration: underline;
    font: var(--font-body-1);
    font-size: 16px;
    line-height: 24px;
    color: rgba(var(--primary-text-color));
}

.error_message {
    width: 100%;
    height: max-content;
    font: var(--font-body-1);
    font-size: 18px;
    background: rgba(var(--primary-error-color));
    color: rgba(var(--primary-text-dark-color));
    border-radius: 2px;
    padding: 10px 10px;
}

.or {
    font-family: Noir No1;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #171831;
}

.google {
    background-color: transparent;
    display: grid;
    grid-auto-flow: column;
    column-gap: 15px;
    padding: 10px 10px 10px 0px;
    font-family: Noir No1;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #171831;
    text-transform: none;
}

.google:active {
    background: transparent !important;
    opacity: 0.5;
}

.google_icon {
    width: 40px;
    background-color: white;
    border-radius: 10px;
}