.file_selector {
   width: max-content;
   height: max-content;
}

.icon_button {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 80px;
    height: 80px;
    border-radius: 100px;
    padding: 20px;
    background: rgba(var(--dark-bg-color));
    border: none;
}

.icon {
    width: 40px;
    height: 40px;
}

.icon path {
    fill: rgba(var(--primary-text-dark-color));
}