.spinner {
    width: 60px;
    height: 60px;
    animation: rotateWithBoost 1.5s linear infinite;
}

@keyframes rotateWithBoost {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}