.panel {
    --panel-width: 648px;
    --panel-padding-TB: 40px;
    --panel-padding-RL: 110px;
}

.panel {
    position: absolute;
    top: 56px;
    right: 56px;
    width: var(--panel-width);
    min-height: 85%;
    background: rgba(var(--form-bg-color));
    padding: var(--panel-padding-TB) var(--panel-padding-RL);
    z-index: 3;
    display: grid;
    grid-template-rows: max-content 1fr;
    row-gap: 46px;
}

.switch_text {
    align-self: flex-end;
    font: var(--font-subtitle-1);
    font-size: 20px;
    line-height: 24px;
    color: rgba(var(--primary-text-color));
}

.switch_button {
    border: none;
    background: none;
    text-decoration: underline;
    font: var(--font-subtitle-1);
    font-size: 20px;
    line-height: 24px;
    color: rgba(var(--primary-text-color));
}

.spacer {
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 100%;
    height: 20px;
    background: transparent;
}

@media(max-width: 1440px) {
    .panel {
        --panel-width: calc(548px + (648 - 548) * ((100vw - 769px) / (1440 - 769)));
        --panel-padding-RL: calc(85px + (110 - 85) * ((100vw - 769px) / (1440 - 769)));
    }
}

@media(max-width: 1150px) {
    .panel {
        top: 115px;
    }
}

@media(max-width: 768px) {
    .panel {
        --panel-width: calc(316.5px + (648 - 316.5) * ((100vw - 375px) / (768 - 375)));
        --panel-padding-TB: calc(24px + (40 - 24) * ((100vw - 375px) / (768 - 375)));
        --panel-padding-RL: calc(16px + (110 - 16) * ((100vw - 375px) / (768 - 375)));
    }

    .panel {
        top: 90px;
        right: 7.81%;
    }
}